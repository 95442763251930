.chat-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 900px;
    width: 91.3rem;
    padding: 3rem;
    box-sizing: border-box;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 28px;
    font-weight: 300;
}

.chat-box {
    width: 100%;
    height: 90rem;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 55px;
    padding: 5px;
    margin: 50px 0 20px 0;
}

.chat-box::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

.new-message-form {
    display: flex;
    align-self: flex-end;
    align-items: center;
    width: 100%;
    max-height: 74px;
    padding: 2px 10px 2px 30px;
    border-radius: 500px;
    background-color: #637194;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
}

.sent-message {
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
    gap: 10px;
}

.received-message {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.message-container {
    gap: 10px;
    line-height: 20px;
    border-radius: 5px;
    padding: 15px 10px 10px 10px;
    background-color: #637194;
    color: #E0E0E0;
    align-self: flex-end;
}

.new-message-field {
    flex: 1;
    border: none;
    resize: none;
    font-size: 1.2rem;
    height: 72px;
    font-family: 'Poppins';
    color: #E0E0E0;
    background-color: #637194;
    overflow: hidden;
    padding-top: 24px;
}

.send-message-button {
    cursor: pointer;
}
