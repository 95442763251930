div.rating-container ul {
    display: flex;

    margin: 0;
    padding: 0;
}
div.rating-container ul li{
    margin: 10px 10px 0 0;
}
div.rating-container ul li button {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: #637194;
    background-color: transparent;
    border: 2px solid #637194;
    border-radius: 8px;
    width: 32px;
    line-height: 28px;
    padding: 0;

    align-items: center;
    justify-content: center;
}
div.rating-container ul li button:hover {
    color: #637194;
    border-color: #637194;
}
div.rating-container ul li button:focus {
    box-shadow: 0 0 0;
}
div.rating-container ul li button.rating__item--active {
    color: #FFF;
    background-color: #637194;
}