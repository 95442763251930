.avatar-container {
    display: block;
    width: 64px;
    height: 64px;
}

.avatar-container img {
    width: 64px;
    height: 64px;
    border: 2px solid rgb(99, 113, 148);
    border-radius: 100%;
    margin: 0 80px 0 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}
