
.chat-container {
    display: flex;
    justify-content: center;
    margin-top: -35px;
    margin-right: 30px ;
}

.info-card {
    display: flex;
    margin-bottom: 3.7rem;
    flex-direction: column;
    width: 29.7rem;
    height: 900px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.image-container {
    display: flex;
    justify-content: center;
    height: 32rem;
    overflow: hidden;
    background-color: #E0E0E0;
}

.image-container img {
    padding: 0;
    border-collapse: collapse;
    height: 110%;
    object-fit: cover;
}

.chat-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 57rem;
    overflow: hidden;
    background-color: #637194;
    color: #E0E0E0;
    font-style: normal;
    font-weight: 300;
    padding: 10px;
    gap: 55px;

}

.doctor-info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
}


.doctor-contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
