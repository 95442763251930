.checkbox-container {
    display: flex;
    gap: 10px;
}

.checkbox {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid #637194;
    box-sizing: border-box;
    display: flex;
    caret-color: transparent;
}

.checked {
    background-color: #637194;
}

.checkbox input {
    width: 100%;
    opacity: 0;
}
