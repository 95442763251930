.avatar-upload-container {
    display: flex;
    padding: 0 0 90px 0;
    align-items: center;
    justify-content: center;
}
.avatar-upload {
    display: block;
    position: relative;
    width: 180px;
    height: 180px;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52.2px;
    height: 52.2px;
    margin-bottom: 0;
    border-radius: 100%;
    background: rgb(98, 112, 147);
    border: 3px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-preview .image-preview {
    width: 180px;
    height: 180px;
    border: 3px solid rgb(99, 113, 148);
    border-radius: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}
