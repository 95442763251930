@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@200;400;600;700;800&display=swap");
@import "rsuite/dist/rsuite.css";
@import 'bootstrap/dist/css/bootstrap.min.css';

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

html {
    height: 100%;
}

body {
    font-family: "Poppins";
    color: #637194;
    background-color: #b4d0d1;
    background-image: linear-gradient(180deg, #ffffff 0%, #b4d0d1 100%);
    width: 100%;
    min-width: 992px;
    height: 100%;
    overflow-x: hidden;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none !important;
}

ul,
ul li {
    margin: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
p {
    font-weight: 300;
    margin: 0;
    padding: 0;
}

p+p {
    margin: 0;
}

strong {
    font-weight: 700;
}

input,
select,
textarea {
    font-family: "Caveat";
}

.mask {
    position: relative;
    background: url(../images/bkg.png) no-repeat center bottom;
}

.min-zeebras {
    min-height: 1080px;
}

.inner {
    background-image: linear-gradient(180deg, #cedde3 0%, #b4d0d1 46.88%);
}

.clear {
    clear: both;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.container {
    display: block;
    max-width: 1120px;
    padding: 0;
}

.default {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;
    padding: 90px 30px 120px 30px;
    text-align: center;

    align-items: center;
}

.zeebras {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
}

/* FIRST ACCESS */
.first-access {
    position: relative;
    color: #4e9289;
    background-color: #bbded3;
    background-image: linear-gradient(180deg, #ffffff 25.97%, #bbded3 100%);
}

.first-access.inner {
    background-image: linear-gradient(180deg, #cee5e1 0%, #b2d6cf 100%);
}

.first-access .presentation .btn,
.first-access .login .btn,
.first-access .forgot .btn,
.first-access .new-password .btn {
    color: #4e9289;
    border: 2px solid #4codeee9289;
}

.first-access .login .form-codee .form-control {
    color: #4e9289;
}

/* PRESENTATION */
.presentation h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    margin: 0;
}

.presentation h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    padding: 0 0 80px 0;
}

.presentation p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
    padding: 0 0 50px 0;
}

.presentation p strong {
    font-weight: 400;
}

.presentation span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 40px 0 0 0;
}

.presentation .logo {
    margin: 0 0 90px 0;
}

.presentation .btn {
    font-size: 30px;
    font-weight: 600;
    color: #637194;
    background: transparent;
    border: 2px solid #637194;
    border-radius: 16px;
    margin: 30px 0 0 0;
    padding: 10px 60px;
}

/* LOGIN */
.login h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    margin: 0;
}

.login p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
    padding: 0;
}

.login p strong {
    font-weight: 400;
}

.login a {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 40px 0;
    text-decoration: none;
}

.login .logo {
    margin: 0 0 90px 0;
}

.login .btn {
    font-size: 26px;
    font-weight: 500;
    color: #637194;
    background: transparent;
    border: 2px solid #637194;
    border-radius: 16px;
    margin: 20px 0 0 0;
    padding: 10px 20px;
}

.login .form-codee {
    position: relative;
    background: transparent url(../images/input-shadow.png) no-repeat center bottom !important;
    margin: 0 0 40px 0;
    padding: 0 0 20px 0;
}

.login .form-codee svg {
    position: absolute;
    right: 5px;
    top: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.login .form-codee .form-control {
    font-size: 40px;
    background: none transparent;
    border: 0;
    width: 468px;
    line-height: 50px;
    text-align: center;
}

.login .form-codee .validation-error {
    bottom: -5px;
}

/* FORGOT */
.forgot h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    margin: 0;
}

.forgot p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
    padding: 0;
}

.forgot .logo {
    margin: 0 0 90px 0;
}

.forgot .btn {
    font-size: 30px;
    font-weight: 600;
    color: #637194;
    background: transparent;
    border: 2px solid #637194;
    border-radius: 16px;
    margin: 20px 0 0 0;
    padding: 10px 20px;
}

.forgot .form-codee {
    background: url(../images/input-shadow.png) no-repeat center bottom;
    margin: 40px 0;
    padding: 0 0 20px 0;
}

.forgot .form-codee .form-control {
    font-size: 40px;
    background: none transparent;
    border: 0;
    width: 468px;
    line-height: 50px;
    text-align: center;
}

/* NEW PASSWORD */
.new-password h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    margin: 0;
}

.new-password p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
    padding: 0;
}

.new-password p strong {
    font-weight: 400;
}

.new-password .logo {
    margin: 0 0 90px 0;
}

.new-password .btn {
    font-size: 30px;
    font-weight: 600;
    color: #637194;
    background: transparent;
    border: 2px solid #637194;
    border-radius: 16px;
    margin: 20px 0 0 0;
    padding: 10px 20px;
}

.new-password .form-codee {
    background: url(../images/input-shadow.png) no-repeat center bottom;
    margin: 0 0 60px 0;
    padding: 0 0 20px 0;
}

.new-password .form-codee .form-control {
    font-size: 40px;
    background: none transparent;
    border: 0;
    width: 468px;
    line-height: 50px;
    text-align: center;
}

/* HEADER */
#header {
    padding: 60px 0;
}

#header .header-right {
    display: flex;

    float: right;

    align-items: center;
    justify-content: right;
}

#header .header-right h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

#header .header-right p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
}

#header .header-right .name-level {
    align-items: end;
}

#header .header-right .photo {
    display: block;
    width: 64px;
    height: 64px;
    border: 3px solid #637194;
    border-radius: 100px;
    margin: 0 80px 0 20px;
    overflow: hidden;
}

#header .header-right .photo img {
    width: 100%;
}

/* SIDEBAR */
#sidebar {
    position: fixed;
    right: -340px;
    top: 0;
    background-color: #f1f5ff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
    width: 340px;
    height: 100%;
    padding: 15px;
    overflow: auto;
    transition: 1s;
    z-index: 500;
}

/* width */
#sidebar::-webkit-scrollbar {
    width: 4px;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
    background: #f1f5ff;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
    background: #b4b4b4;
}

/* Handle on hover */
#sidebar::-webkit-scrollbar-thumb:hover {
    background: #637194;
}

#sidebar.show {
    right: 0;
}

#sidebar .navbar-nav {
    margin: 0 0 40px 0;
    padding: 0 20px;
}

#sidebar .navbar-nav li.nav-item {
    display: flex;
    height: 60px;
    margin: 5px 0;
    align-items: center;
}

#sidebar .navbar-nav li.nav-item a {
    font-size: 24px;
    font-weight: 300;
    color: #b4b4b4;
    line-height: 32px;
    text-decoration: none;
}

#sidebar .navbar-nav li.nav-item svg {
    margin: 0 10px 0 0;
}

#sidebar .navbar-nav li.nav-item:first-child {
    margin: 30px 0;
}

#sidebar .profile {
    display: block !important;
    min-height: 64px;
    margin-bottom: 50px;
}

#sidebar .profile h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

#sidebar .profile p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
}

#sidebar .profile .name-level {
    float: right;
    width: calc(100% - 84px);
    padding-block: 8px;
    padding-left: 20px;
}

#sidebar .profile .avatar-container {
    float: left;
    margin-left: 0;
    padding-block: 8px;
}

#sidebar .profile .photo img {
    width: 100%;
}

/* CONTENT */
.container {
    position: relative;
}

/* GENERAL */
::placeholder {
    color: #b9b9c3 !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #b9b9c3 !important;
}

::-ms-input-placeholder {
    color: #b9b9c3 !important;
}

a {
    color: #637194;
    text-decoration: underline;
}

a:hover {
    color: #637194;
}

.divider {
    border: 1px solid #637194;
    opacity: 0.35;
}

.shadow-divider {
    background: url(../images/question-shadow.png) no-repeat center bottom;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
}

.fdefault {
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}

.fdefault strong {
    font-weight: 700;
}

.f41 {
    font-size: 41px;
    line-height: 44px;
}

.f36 {
    font-size: 36px;
    line-height: 40px;
}

.f32 {
    font-size: 32px;
    line-height: 36px;
}

.f30 {
    font-size: 30px;
    line-height: 32px;
}

.f28 {
    font-size: 28px;
    line-height: 30px;
}

.f26 {
    font-size: 26px;
    line-height: 32px;
}

.f25 {
    font-size: 25px;
    line-height: 28px;
}

.f24 {
    font-size: 24px;
    line-height: 28px;
}

.f20 {
    font-size: 20px;
    line-height: 28px;
}

.f18 {
    font-size: 18px;
    line-height: 20px;
}

.f16 {
    font-size: 16px;
    line-height: 18px;
}

.f14 {
    font-size: 14px;
    line-height: 18px;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 500;
}

.light {
    font-weight: 300;
}

.title-data {
    width: 320px;
    line-height: 48px;
}

.border-data {
    border-bottom: 1px solid #637194;
    padding: 0 0 5px 0;
}

.interaction-time {
    font-size: 18px;
    font-weight: 400;
}

.interaction-time span {
    font-size: 24px;
}

.thumbnailStream {
    display: flex;
    background: #d4e6e3;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 10px;
    width: 130px;
    height: 80px;
}

.thumbnailStream img {
    border-radius: 5px;
    width: 90%;
}

.icon {
    display: flex;
    background: #d4e6e3;
    border-radius: 1000px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.icon.no-bkg {
    background: transparent;
}

.icon.big {
    width: 100px;
    height: 100px;
}

.icon.big svg {
    width: 60px;
    height: 60px;
}

.opacity60 {
    opacity: 0.6;
}

/* MODAL */
.modal-content {
    background-color: #e3ebff;
}

.modal-content .close {
    position: absolute;
    right: 25px;
    top: 25px;
    background-color: transparent !important;
    border: 0 !important;
    padding: 0;
}

.modal-body {
    padding: 60px 60px 30px 60px;
}

/* FORM CONTROL */
.form-control {
    padding: 5px 15px;
}

.form-control:focus {
    box-shadow: 0 0 0;
}

.questions .form-codee {
    background: url(../images/question-shadow.png) no-repeat center bottom;
    margin: 0 0 20px 0;
    padding: 0 0 30px 0;
}

.questions .form-codee .form-control {
    font-size: 28px;
    color: #637194;
    background: none transparent;
    border: 0;
    padding: 0;
}

.alternative-question {
    display: flex;

    padding: 0 0 5px 0;

    align-items: flex-start;
}

.alternative-question input {
    width: 20px;
    height: 20px;
}

.alternative-question label {
    flex: 1;

    padding: 0 0 0 10px;
}

.validation-error {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 300;
    color: #f00;
    line-height: 20px;
    width: 100%;
    text-align: center;
}

/* BUTTONS */
.btn {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: #637194;
    border: 2px solid #637194;
    border-radius: 10px;
    width: auto;
    line-height: 28px;
    padding: 4px 18px;
}

.btn:hover {
    color: #637194;
    border-color: #637194;
}

.btn:focus {
    box-shadow: 0 0 0;
}

.btn.btn-left {
    font-size: 22px;
    font-weight: 700;
    background: url(../images/btn-left.png) no-repeat;
    border: 0;
    width: 308px;
    height: 110px;
    line-height: 24px;
    padding: 0 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.btn.btn-right {
    font-size: 20px;
    font-weight: 700;
    background: url(../images/btn-right.png) no-repeat;
    border: 0;
    width: 308px;
    height: 110px;
    line-height: 24px;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.btn.btn-secondary {
    background-color: transparent;
}

.btn-yes {
    display: flex;
    font-size: 24px;
    font-weight: 700;
    background: url(../images/btn-left.png) no-repeat;
    background-size: contain;
    border: 0;
    width: 156px;
    height: 54px;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.btn-no {
    display: flex;
    font-size: 24px;
    font-weight: 700;
    background: url(../images/btn-right.png) no-repeat;
    background-size: contain;
    border: 0;
    width: 156px;
    height: 54px;
    padding: 0;
    align-items: center;
    justify-content: center;
}


.prev {
    position: absolute;
    left: -100px;
    top: 0;

    font-size: 18px;
    font-weight: 300;
    color: #637194;
    background: url(../icons/left.png) no-repeat center top;
    background-size: 40px;
    width: 80px;
    line-height: 36px;
    padding: 100px 0 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.next {
    position: absolute;
    right: -100px;
    top: 0;

    font-size: 18px;
    font-weight: 300;
    color: #637194;
    background: url(../icons/right.png) no-repeat center top;
    background-size: 40px;
    width: 80px;
    line-height: 36px;
    padding: 100px 0 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.buttons-icons svg {
    width: 28px;
    height: 24px;
    margin: 0 0 0 20px;
    cursor: pointer;
}

/* ALERTS */

/* MEDIC */
.medic-photo {
    display: block;
    width: 196px;
    height: 196px;
    border: 3px solid #637194;
    border-radius: 196px;
    margin: 0 40px 0 0;
    overflow: hidden;
}

.medic-photo img {
    width: 100%;
}

.medic-name {
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    padding: 136px 0 20px 0;
}

.medic-table {
    border: 0;
    width: 100%;
}

.medic-table th {
    font-size: 25px;
    font-weight: 300;
    line-height: 31px;
    padding: 10px 0;
}

.medic-table td {
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    padding: 10px 0 10px 40px;
}

/* MESSAGES */
.answer {
    padding: 0 0 0 115px;
}

.your-message {
    border: 1px solid #637194;
    border-radius: 8px;
    margin: 30px 0 0 230px;
    padding: 15px;
}

.back {
    background: url(../icons/left.png) no-repeat right center;
    background-size: cover;
    width: 20px;
    height: 40px;
}

.form-message {
    position: relative;
    padding: 0 0 30px 0;
}

.form-message label {
    font-size: 24px;
    font-weight: 300;
    margin: 0 0 5px 0;
}

.form-message .form-control {
    font-family: "Poppins";
    color: #637194;
    background: transparent;
    border: 1px solid #637194;
    border-radius: 10px;
    height: auto;
    padding: 10px;
}

.form-message .validation-error {
    bottom: 10px;
    text-align: right;
}


/* PROGRESS */
.steps-container {
    border: 2px solid #637194;
    border-radius: 20px;
    margin: 0 0 15px 0;
    padding: 15px;
}

.progress {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 10px !important;
}

.progress-bar {
    background-color: #637194;
    border-radius: 10px;
}


.steps .step-index {
    display: flex;
    font-size: 56px;
    font-weight: bold;
    width: 120px;
    padding: 0 15px 0 0;
    align-items: center;
    justify-content: center;
}

/* TIMELINE */
#timelineContainer {
    display: flex;

    width: 38px;
    height: 140px;

    align-self: center;
    align-items: center;
}

.rs-timeline-align-left .rs-timeline-item {
    padding-left: 0;
}

.rs-timeline-align-left .rs-timeline-item-tail {
    left: 18px;
}

.rs-timeline-item-tail {
    background-color: #637194;
    opacity: 0.4;
}

.rs-timeline-item-dot::before {
    background-color: #9eabc0;
    width: 38px;
    height: 38px;
}

.rs-timeline-item-last .rs-timeline-item-dot::before {
    background-color: #637194;
}

.rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 68px;
}


/* PROFILE */
.profile {
    padding: 0 60px 0 0;
}


.profile-progress {
    background-color: rgba(99, 113, 148, 0.25);
    border-radius: 10px;
    box-shadow: 10px 10px 30px rgba(99, 113, 148, 0.15);
    padding: 15px;
}

.profile-progress .rs-progress-info-status {
    font-size: 12px;
    color: #FFF;
}

.profile-progress .rs-progress-line {
    padding: 10px;
}

.profile-progress .rs-progress-line-outer {
    width: 3px !important;
}

.profile-progress .rs-progress-line-inner {
    background-color: rgba(0, 0, 0, 0.1);
    width: 3px !important;
}

.profile-progress .rs-progress-line-bg {
    background-color: #FFF;
    width: 3px !important;
}

.profile-progress .card-summary {
    background-color: #637194;
    border-radius: 10px;
    padding: 20px;
}

.profile-progress .card-summary h4 {
    font-size: 16px;
    color: #FFF;
}

.profile-progress .card-summary h4 strong {
    font-size: 30px;
    font-weight: bold;
}

.profile-progress .card-summary p {
    font-size: 14px;
    color: #FFF;
    line-height: 18px;
    padding: 10px 0 0 0;
}


.MuiBox-root {
    padding: 30px 0 !important;
}

.tabs-codee.MuiBox-root {
    padding: 0 !important;
}

.tabs-codee .MuiButtonBase-root {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    color: #637194;
    min-width: auto;
    line-height: 28px;
    margin: 0 60px 0 0;
    padding: 0;
    text-transform: none;
    opacity: 0.5;
}

.tabs-codee .MuiButtonBase-root.Mui-selected {
    font-weight: 700;
    color: #637194;
    opacity: 1;
}

.tabs-codee .MuiTabs-indicator {
    background-color: #637194;
    height: 3px;
}



.inputContainer {
    position: relative;
    display: block;
    padding: 0 0 20px 0;
}

.inputContainer label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.inputContainer .form-control {
    font-size: 1.25rem;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #637194;
    border-radius: 0;
    padding: 5px 0;
}

.view-password {
    position: absolute;
    right: calc(var(--bs-gutter-x) * .5);
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}




.answer-codee {
    position: relative;
    font-family: "Caveat";
    background: transparent url(../images/input-shadow.png) no-repeat center bottom !important;
    background-size: 1080px !important;
    margin: 0 0 15px 0;
    padding: 0 0 55px 0;
}

/*############### Loading component ############*/
.fallback-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(98, 112, 147, 0.9);
    z-index: 999;

    &.app-loader {
      height: 100vh;
      width: 100vw;
      flex-direction: column;
      .fallback-logo {
        user-select: none;
      }
      .loading {
        margin-top: 3rem;
      }
    }

    .loading {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 3px solid transparent;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      .effect-1,
      .effect-2,
      .effect-3 {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-left: 3px solid white;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .effect-1 {
        position: absolute;
        animation: rotate 1s ease infinite;
      }
      .effect-2 {
        position: absolute;
        animation: rotateOpacity 1s ease infinite 0.1s;
      }
      .effect-3 {
        -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
        animation: rotateOpacity 1s ease infinite 0.2s;
      }

      .loading .effects {
        transition: all 0.3s ease;
      }
    }
  }


@keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes rotateOpacity {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0.1;
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      opacity: 1;
    }
  }

