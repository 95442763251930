/* PAGINATION */
.pagination-container {
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.pagination-container button {
	display: flex;

	background-color: rgba(255,255,255,0.25);
	border: 0;
	border-radius: 36px;
	width: 36px;
	height: 36px;
	
	align-items: center;
	justify-content: center;
}
.pagination-container button svg {
	width: 18px;
	height: 18px;
}

.pagination-container ul {
	background-color: rgba(255,255,255,0.25);
	border-radius: 36px;
	margin: 0 10px;
}

.pagination-container ul li button {
	border-radius: 0;
}
.pagination-container ul li:first-child button {
	border-radius: 36px 0 0 36px;
}
.pagination-container ul li:last-child button {
	border-radius: 0 36px 36px 0;
}

.pagination-container ul li button.pagination__item--active {
	color: #FFF;
	background-color: #637194;
	border-radius: 36px;
}